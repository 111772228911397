import { withDataLayer } from '@digital-spiders/tracking-data';
import { useStore } from '@nanostores/react';
import { toPlainText } from 'astro-portabletext';
import { useEffect } from 'react';
import { $formModalOptions, closeFormModal } from '../../stores/formModalStore';
import Form from './Form';
import * as styles from './FormModalOverlay.module.scss';
import ModalOverlay from './ModalOverlay';

export default function FormModalOverlay() {
  const formModalOptions = useStore($formModalOptions);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (formModalOptions !== null) {
        event.preventDefault();
        closeFormModal();
      }
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [formModalOptions]);

  const fileUrl = formModalOptions
    ? formModalOptions.formModal.fileToDownloadAfterSubmit?.asset.url +
      '?dl=' +
      formModalOptions.formModal.fileToDownloadAfterSubmit.asset.originalFilename
    : '';

  return (
    <ModalOverlay
      open={!!formModalOptions}
      onClose={() => closeFormModal()}
      modalClassName={styles.modal}
    >
      {formModalOptions &&
        formModalOptions.formFieldsWithIds &&
        formModalOptions.formFieldsWithIds.length > 0 && (
          <Form
            formType="modal"
            fields={formModalOptions.formFieldsWithIds}
            title={formModalOptions.formModal.title}
            text={toPlainText(formModalOptions.formModal.text)}
            submitButtonText={formModalOptions.formModal.submitButtonText}
            sanityDocumentId={formModalOptions.formModal._id}
            fileUrlToDownloadAfterSubmit={fileUrl}
            showNotificationWhenFormIsSubmitted
            notificationMessage={{
              portableText: formModalOptions.formModal.successMessage,
              fileUrl,
            }}
            className={styles.form}
            onSubmit={() => {
              withDataLayer(dataLayer => {
                dataLayer.push({
                  event: 'form-submit',
                  type: 'modal-form',
                  id: formModalOptions.formModal.slug.current,
                  path: window.location.pathname,
                });
              });
            }}
          />
        )}
    </ModalOverlay>
  );
}
